let galleryJson = [
  {
    title: "Ike Behar Ivory Suit",
    description: "",
    price: "",
    imageLink:
      "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/bowties-tuxedo/Ike-Behar_Aragon-Ivory-1.png",
  },
  {
    title: "Allure Men's Cafe Brunswick Suit",
    description: "",
    price: "",
    imageLink:
      "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/bowties-tuxedo/allure-men-brunswick-cafe-suit-jacket-separates.png",
  },
  {
    title: "Linen Light Grey Suit",
    description: "",
    price: "",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/262_clayton_1.jpg",
  },
  {
    title: "Green Tuxedo with Black Lapel",
    description: "",
    price: "",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/couture-1910-arlo-green-tuxedo-jacket_1024x1024_2x.jpg",
  },
  {
    title: "Desert Tan Suit",
    description: "",
    price: "",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/DesertTan-01-2000x3000-6d1c23b__51999.jpg",
  },
  {
    title: "Ivory Paisley",
    description: "",
    price: "",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/IvoryChasePaisley.jpg",
  },
  {
    title: "Black Velvet Tuxedo",
    description: "",
    price: "",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/LuxorBlackVelvet.png",
  },
  {
    title: "Women's Tuxedo",
    description: "Available in Black, Ivory, Navy, White",
    price: "",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/whitewomenssuit.jpg",
  },
  {
    title: "Sage Green 3 Piece Suit",
    description: "",
    price: "",
    imageLink:
      "https://d2i5cbxq39t6at.cloudfront.net/bowties-tuxedo/CeledonCouture.jpg",
  },
  {
    title: "Light Blue Brunswick",
    description: "",
    price: "169",
    imageLink:
      "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/bowties-tuxedo/light-blue-brunswick.jpeg",
  },
  {
    title: "Burgandy 3 Piece Suit",
    description: "",
    price: "159 (purchase for $349)",
    imageLink:
      "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/bowties-tuxedo/burgundy-3-piece.jpeg",
  },
  {
    title: "Wine Couture 1901 3 Piece",
    description: "",
    price: "159 (purchase for $349)",
    imageLink:
      "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/bowties-tuxedo/wine-couture-1901-3-piece.jpeg",
  },
  {
    title: "Green 3 Piece",
    description: "",
    price: "159 (purchase for $349)",
    imageLink:
      "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/bowties-tuxedo/green-3-piece.jpeg",
  },
  {
    title: "Dark Grey",
    description: "",
    price: "159 (purchase for $349)",
    imageLink:
      "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/bowties-tuxedo/dark-gray.jpeg",
  },
  {
    title: "Medium Grey",
    description: "",
    price: "159 (purchase for $349)",
    imageLink:
      "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/bowties-tuxedo/medium-gray.jpeg",
  },
  {
    title: "Black",
    description: "",
    price: "159 (purchase for $349)",
    imageLink:
      "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/bowties-tuxedo/black.jpeg",
  },
  {
    title: "Navy Blue",
    description: "",
    price: "159 (purchase for $349)",
    imageLink:
      "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/bowties-tuxedo/navy-blue.jpeg",
  },
  {
    title: "New Navy Blake by Ike Behar #388",
    description: "",
    price: "169",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/blue_blake_ike_behar_1024x1024.png",
  },
  {
    title: "Ike Behar Lane Dusk",
    description: "",
    price: "169",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/ike_behar_dusk_blue.jpg",
  },
  {
    title: "Navy Ike Behar Slim",
    description: "",
    price: "169",
    imageLink:
      "https://pg.b5z.net/get/nb5z/s200-*/zirw/74/i/u/10106892/i/pdir/192/blakeslim.jpg?bd=84",
  },
  {
    title: "New Ike Behar Burgundy Marbella",
    description: "",
    price: "169",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/marbella.jpg",
  },
  {
    title: "Ike Behar Collin Navy Suit",
    description: "",
    price: "169",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/collinikebehar.jpg",
  },
  {
    title: "New Jackson Slim fit by Ike Behar",
    description: "",
    price: "169",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/image-ike-behar-jackson-lg.jpg",
  },
  {
    title: "Black Waverly Slim Fit by Ike Behar",
    description: "",
    price: "169",
    imageLink:
      "https://photo-hosting-bucket.s3.us-west-1.amazonaws.com/bowties-tuxedo/Gallery/black-waverly-tuxedo-490x700.jpg",
  },
  {
    title: "Slate Blue Julian by Ike Behar #399",
    description: "",
    price: "169",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/julian-couple-700x900.jpg",
  },
  {
    title: "Ike Behar White Waverly",
    description: "",
    price: "159",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/whitewaverly.jpg",
  },
  {
    title: "Ivory Ike Behar Waverly",
    description: "",
    price: "159",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/ike-behar-ivory-waverly.png",
  },
  {
    title: "Heather Grey Slim Fit Bartlett by Allure Style #320",
    description: "",
    price: "159",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/allure_heather_slim_fit.jpg",
  },
  {
    title: "Light Grey Slim Fit by Allure Style #320",
    description: "",
    price: "159",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/allure_heather.jpg",
  },
  {
    title: "Heather Grey Twilight #322",
    description: "",
    price: "144",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/heathertwilightcloseup.jpg",
  },
  {
    title: "Steel Grey Slim Fit Bartlett by Allure Style #340",
    description: "",
    price: "159",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/allure_steel_slim_fit.jpg",
  },
  {
    title: "Slim Fit Steel Grey #340",
    description: "",
    price: "159",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/allure-steel.jpg",
  },
  {
    title: "Steel Grey Twilight #342",
    description: "",
    price: "144",
    imageLink:
      "https://pg.b5z.net/get/nb5z/s900-*/zirw/34/i/u/10106892/i/steelgrey.jpg",
  },
  {
    title: "Allure Slim Fit Bartlett in Black Style #810",
    description: "",
    price: "159",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/allure_black_slim_fit.jpg",
  },
  {
    title: "Allure Black Slim Fit #810",
    description: "",
    price: "",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/allure_slim_fit.jpg",
  },
  {
    title: "Allure Slim Fit Bartlett In Tan Style #200",
    description: "",
    price: "159",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/allure_tan_slim_fit.jpg",
  },
  {
    title: "Portofino w/ Grey Pant #301",
    description: "",
    price: "169",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/slate_portofino_full.jpg",
  },
  {
    title: "Slate Grey Slim Fit Portofino w/ Black Pant #301",
    description: "",
    price: "169",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/slate_portofino_slim_fit.jpg",
  },
  {
    title: "Red Velvet Slim Fit w/ Black Lapel (available in Royal / Black)",
    description: "",
    price: "159",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/red-velvet-jacket.jpg",
  },
  {
    title: "Royal Blue Velvet Sport Coat",
    description: "",
    price: "159",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/bluevelvet.jpg",
  },
  {
    title: "Black Paisley Coat",
    description: "159",
    price: "",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/black_paisley_coat.jpg",
  },
  {
    title: "Burgundy Paisley Coat",
    description: "159",
    price: "",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/burgundy_paisley_coat.jpg",
  },
  {
    title: "Charcoal Paisley Coat",
    description: "159",
    price: "",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/charcoal_paisley_coat.jpg",
  },
  {
    title: "Green Paisley Coat",
    description: "159",
    price: "",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/green_paisley_coat.jpg",
  },
  {
    title: "Red Paisley Coat",
    description: "159",
    price: "",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/red_paisley_coat.jpg",
  },
  {
    title: "White Paisley Coat",
    description: "159",
    price: "",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/white_paisley_coat.jpg",
  },
  {
    title: "Black Zenith Sequence Peak Lapel",
    description: "",
    price: "159",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/zenith_2.jpg",
  },
  {
    title: "Black Twilight #800",
    description: "",
    price: "159",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/jean-yves-black-twilight-zoom.jpg",
  },

  // {
  //   title: "Black La Strada #842",
  //   description: "",
  //   price: "134",
  //   imageLink:
  //     "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/842_lastrada.jpg",
  // },
  // {
  //   title: "Classic 2 Button Peak #722",
  //   description: "",
  //   price: "129",
  //   imageLink:
  //     "https://pg.b5z.net/get/nb5z/s900-*/zirw/34/i/u/10106892/i/722rhodes.jpg",
  // },
  {
    title: "Black 3 Piece Suit #400",
    description: "",
    price: "",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/black_3_piece_suit.jpg",
  },
  {
    title: "Classic Tail #588",
    description: "",
    price: "",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/peak_tailcoat.jpg",
  },
  {
    title: "Black Mandarin Collar Mirage",
    description: "",
    price: "",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/black_mirage.jpg",
  },
  {
    title: "White 2 Button Notch (Slim Fit Available) #748s",
    description: "",
    price: "",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/white_2_button_notch.jpg",
  },
  {
    title: "Tux Shoe",
    description: "",
    price: "",
    imageLink:
      "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/bowties-tuxedo/Tux.jpg",
  },
  {
    title: "Cognac Shoe",
    description: "",
    price: "",
    imageLink:
      "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/bowties-tuxedo/CognacShoe.jpg",
  },
  {
    title: "Light Brown Shoe",
    description: "",
    price: "",
    imageLink:
      "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/bowties-tuxedo/LightBrownShoe.jpg",
  },

  {
    title: "My man Vince looking sharp!",
    description: "",
    price: "",
    imageLink:
      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/bowties-tuxedo/Gallery/mymanvinny.jpg",
  },

  {
    title: "Our Store",
    description: "1",
    price: "",
    imageLink:
      "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/bowties-tuxedo/store4.jpg",
  },
  {
    title: "Our Store",
    description: "2",
    price: "",
    imageLink:
      "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/bowties-tuxedo/store3.jpg",
  },
  {
    title: "Our Store",
    description: "3",
    price: "",
    imageLink:
      "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/bowties-tuxedo/store2.jpg",
  },
  {
    title: "Our Store",
    description: "4",
    price: "",
    imageLink:
      "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/bowties-tuxedo/store1.jpg",
  },
];

export default {
  galleryJson,
};
